// import { Circle, Home, Percent } from "react-feather"
// import { ownUrl } from "../../views/Validator"
// import { AiFillPhone, AiOutlineSmile } from "react-icons/ai"
import { GoShieldCheck, GoTools } from "react-icons/go"
import { DefaultNav } from "./DefualtNav"
import { PiMoneyThin, PiShareNetworkThin, PiUsersThree } from "react-icons/pi"
import { RiCarLine } from "react-icons/ri"
// import { ownUrl } from "../../views/Validator"
import { Calendar, Circle, User, FileText, Link2 } from "react-feather"
import { BiCategory } from "react-icons/bi"
import { FiUsers } from "react-icons/fi"
import { HiOutlineUsers } from "react-icons/hi"

export const CRMNavigation = [
    ...DefaultNav,
    {
        header: 'CRM'
    },
    // {
    //     id: 'leads',
    //     title: 'Leads',
    //     icon: <PiUsersThree style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customers/leads/'
    // },
    // {
    //     id: 'cross_leads',
    //     title: 'Cross Leads',
    //     icon: <PiShareNetworkThin style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customers/cross_leads'
    // },
    {
        id: 'leads',
        title: 'Leads',
        icon: <HiOutlineUsers size={18} />,
        navLink: '/merchant/leads/'
    },
    {
        id: 'customers',
        title: 'Customers',
        icon: <FiUsers size={18} />,
        navLink: '/merchant/customers/'
    },
    
    {
        id: 'accounts',
        title: 'Accounts',
        icon: <User size={8} />,
        navLink: '/merchant/account/'
    },

    {
        id: 'calender',
        title: 'Calender',
        icon: <Calendar size={18} />,
        navLink: '/merchant/calender/'
    },
    {
        id: 'Invoices',
        title: 'Invoices',
        icon: <FileText size={18} />,
        navLink: '/merchant/invoices/'
    },
    {
        id: 'products',
        title: 'Products',
        icon: <Circle size={18} />,
        navLink: '/merchant/products/'
    },
    {
        id: 'category',
        title: 'Category',
        icon: <BiCategory size={18} />,
        navLink: '/merchant/category/'
    },
    {
        id: 'Integrations',
        title: 'Integrations',
        icon: <Link2 size={18} />,
        navLink: '/merchant/integration/'
    }
    

    // {
    //     id: 'finance',
    //     title: 'Finance',
    //     icon: <PiMoneyThin style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customer/all_cust_dashboard/add_finance/'
    // },
    // {
    //     id: 'servicing',
    //     title: 'Servicing',
    //     icon: <GoTools style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customer/all_cust_dashboard/add_servicing/'
    // },
    // {
    //     id: 'insurance',
    //     title: 'Insurance',
    //     icon: <GoShieldCheck style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customers/insurance'
    // },
    // {
    //     id: 'vehicle',
    //     title: 'Vehicle',
    //     icon: <RiCarLine style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customers/vehicle/'
    // },
    // {
    //     id: 'cross_leads',
    //     title: 'Cross Leads',
    //     icon: <PiShareNetworkThin style={{ marginRight: '18px' }} />,
    //     navLink: '/merchant/customers/cross_leads'
    // }

]