// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { chatbotNavigation } from '../navigation/Apps/ChatbotNav'
import { CRMNavigation } from '../navigation/Apps/CRM'
import { DefaultNav, FooterNav } from '../navigation/Apps/DefualtNav'
import { EmailNavigation } from '../navigation/Apps/Email'
import { flashAccountsNavigation } from '../navigation/Apps/FlashAccounts'
import { InfinitiNavigation } from '../navigation/Apps/Infiniti'
import { OhMyCustomerNavigation } from '../navigation/Apps/OhMyCustomer'
import { ProductReviewNavigation } from '../navigation/Apps/ProductReview'
import { referralNavigation } from '../navigation/Apps/Referral'
import { SuperLeadzNavigation } from '../navigation/Apps/SuperLeadz'
import { WhatsappNavigation } from '../navigation/Apps/Whatsapp'
export const getBookmarks = createAsyncThunk('layout/getBookmarks', async () => {
  const response = await axios.get('/api/bookmarks/data')
  return {
    data: response.data.suggestions,
    bookmarks: response.data.bookmarks
  }
})

export const updateBookmarked = createAsyncThunk('layout/updateBookmarked', async id => {
  await axios.post('/api/bookmarks/update', { id })
  return id
})

export const getCurrRoutes = (data) => {
  let navigation = []
  const userPermission = data ? {appName : data} : JSON.parse(localStorage.getItem("userPermission") ?? "{}")

  if (userPermission?.appName === "superleadz") {
    navigation = [...SuperLeadzNavigation, ...FooterNav]
  } else if (userPermission?.appName === "infiniti") {
    navigation = [...InfinitiNavigation, ...FooterNav]
  } else if (userPermission?.appName === "referral") {
    navigation = [...referralNavigation, ...FooterNav]
  } else if (userPermission?.appName === "flash_accounts") {
    navigation = [...flashAccountsNavigation, ...FooterNav]
  } else if (userPermission?.appName === "product_review") {
    navigation = [...ProductReviewNavigation, ...FooterNav]
  } else if (userPermission?.appName === "crm") {
    navigation = [...CRMNavigation, ...FooterNav]
  } else if (userPermission?.appName === "whatsapp") {
    navigation = [...WhatsappNavigation, ...FooterNav]
  } else if (userPermission?.appName === "email") {
    navigation = [...EmailNavigation, ...FooterNav]
  } else if (userPermission?.appName === "oh_my_customer") {
    navigation = [...OhMyCustomerNavigation, ...FooterNav]
  } else if (userPermission?.appName === "chatbot") {
    navigation = [...chatbotNavigation, ...FooterNav]
  } else {
    navigation = DefaultNav
  }
  return navigation
}
const initialState = {
  query: '',
  bookmarks: [],
  suggestions: [],
  appRoutes: getCurrRoutes() ?? []
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    },
    setAppRoutes: (state, action) => {
      console.log(action.payload, "aaaaHITTTTT")
      state.appRoutes = getCurrRoutes(action.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find(item => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

export const { handleSearchQuery, setAppRoutes } = layoutSlice.actions

export default layoutSlice.reducer
