// src/features/counterSlice.js
import { createSlice } from "@reduxjs/toolkit"

// const initialState = { viewCatalogModalLiveChat: false }
const initialState = {
  membershipPlan: {

  },
  livechatData: {
    viewCatalogModalLiveChatData: {
      modalView: false,
      productList: []
    }
  }
}

export const whatsappSlice = createSlice({
  name: "whatsappSlice",
  initialState,
  reducers: {
    setViewCatalogModalLiveChat: (state, action) => {
      state.livechatData.viewCatalogModalLiveChatData.modalView = action.payload
    },
    setLivechatCatalogModalProducts: (state, action) => {
      state.livechatData.viewCatalogModalLiveChatData.productList = action.payload
    },

    setWaMembershipPlan: (state, action) => {
      state.membershipPlan = action.payload
    }
  }
})

export const { setViewCatalogModalLiveChat, setLivechatCatalogModalProducts, setWaMembershipPlan } = whatsappSlice.actions

export default whatsappSlice.reducer
