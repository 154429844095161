import { Home } from "react-feather"

import { DefaultNav } from "./DefualtNav"
export const chatbotNavigation = [
    ...DefaultNav,
    {
        header: 'chatbot'
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Home size={20} />,
        navLink: '/merchant/chatbot/reports'
    }
]